const moment = require('moment');

const buildDescription = (epiloggData) => {
    if(!epiloggData || Object.keys(epiloggData).length === 0) {
        return 'Create a free online obituary on Epilogg.com. Your loved one’s legacy will live forever on Epilogg. No character restrictions and completely free of charge.';
    }

    let description = epiloggData.displayName;

    if(description) {
        // console.log('dob', epiloggData.dateOfBirth)
        if(epiloggData.dateOfBirth) {
            description += ` was born in ${moment(epiloggData.dateOfBirth.substring(0, 10)).format('YYYY')}`;
        }
        if(epiloggData.dateOfBirth || epiloggData.dateOfDeath) {
            description += ' and'
        }
        if(epiloggData.dateOfDeath) {
            description += ` died in ${moment(epiloggData.dateOfDeath.substring(0, 10)).format('YYYY')}`;
        }
        if(epiloggData.locationAddress) {
            description += ` in ${epiloggData.locationAddress}`;
        }
        description += '. Read the obituary online.';
        return description;
    }

    return 'Create a free online obituary on Epilogg.com. Your loved one’s legacy will live forever on Epilogg. No character restrictions and completely free of charge.';
}

const buildMetadata = epiloggData => {
    if(!epiloggData || Object.keys(epiloggData).length === 0) {
        return 'Create a Free Online Obituary – Life Stories That Live On | Epilogg';
    }

    let title = epiloggData.displayName;

    if(title) {
        title += ' Obituary'
        if(epiloggData.dateOfBirth || epiloggData.dateOfDeath) {
            let dateString = '(';

            // console.log('dob', typeof epiloggData.dateOfBirth)
            if(epiloggData.dateOfBirth) {
                dateString += moment(epiloggData.dateOfBirth.substring(0, 10)).format('YYYY');
            }

            if(epiloggData.dateOfBirth && epiloggData.dateOfDeath) {
                dateString += ' - ';
            }

            if(epiloggData.dateOfDeath) {
                dateString += moment(epiloggData.dateOfDeath.substring(0, 10)).format('YYYY');
            }

            dateString += ')';

            title += ` ${dateString}`;
        }

        if(epiloggData.locationAddress) {
            title += ` - ${epiloggData.locationAddress}`;
        }

        title += ' | Epilogg'

        return title;
    }

    return 'Create a Free Online Obituary – Life Stories That Live On | Epilogg';
};

module.exports = { buildDescription, buildTitle: buildMetadata };
