import 'normalize.css';
import "react-datepicker/dist/react-datepicker.css";
import 'scss/general.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, compose, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {AppContainer} from 'react-hot-loader';
import TagManager from 'react-gtm-module';

import Root from 'Root/Root';

import {rootReducer} from 'store/reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

const rootEl = document.getElementById('root');

const tagManagerArgs = {
    gtmId: 'GTM-TMRPNHZ'
};

TagManager.initialize(tagManagerArgs);
console.log('api url: ', process.env.REACT_APP_API_URL);
ReactDOM.render(
    <AppContainer>
        <Root store={store} />
    </AppContainer>,
    rootEl,
);

if (module.hot) {
    module.hot.accept('Root/Root', () => {
        const NextRoot = require('Root/Root').default; // eslint-disable-line global-require
        ReactDOM.render(
            <AppContainer>
                <NextRoot store={store} />
            </AppContainer>,
            rootEl,
        );
    });
}
